import './App.css';
import Principal from './componentes/Principal';

const App = () => {
  
  return (
    <>
    
    <Principal />
    </>
  );
}

export default App;